<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">安知课堂</a>
          <i>></i>
          <a href="javascript:;">就业报名单</a>
        </span>
      </div>
      <div class="framePage-body framePage-tabs">
        <el-tabs v-model="activeName" @tab-click="tabChange">
          <el-tab-pane label="安知公益" name="publicWelfare_1">
            <publicWelfare_1 ref="publicWelfare_1" v-if="activeName==='publicWelfare_1'" key="publicWelfare_1"  />
          </el-tab-pane>
          <el-tab-pane label="就业服务" name="publicWelfare_2">
            <publicWelfare_2 ref="publicWelfare_2"  v-if="activeName==='publicWelfare_2'" key="publicWelfare_2" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import publicWelfare_1 from "@/views/miniApp/publicWelfarePromotion/publicWelfare_1.vue";
import publicWelfare_2 from "@/views/miniApp/publicWelfarePromotion/publicWelfare_2.vue";
export default {
  components: {
    publicWelfare_1,
    publicWelfare_2
  },
  data: function () {
    return {
      // tap 选择
      activeName: "publicWelfare_1",
    };
  },
  mounted() {},
  created() {},
  methods: {
    // 切换tap
    tabChange(tab) {
      this.activeName = tab.name;
    },
  },
};
</script>

<style>
</style>