<template>
  <div>
    <div
      class="operationControl"
      style="display: flex; flex-direction: column; align-items: flex-start"
    >
      <div class="searchbox">
        <div title="姓名" class="searchboxItem ci-full">
          <span class="itemLabel">姓名:</span>
          <el-input
            v-model="retrievalData.userName"
            type="text"
            size="small"
            placeholder="请输入姓名"
            clearable
          />
        </div>
        <div title="手机号" class="searchboxItem ci-full">
          <span class="itemLabel">手机号:</span>
          <el-input
            v-model="retrievalData.mobile"
            type="text"
            size="small"
            placeholder="请输入联系方式"
            clearable
          />
        </div>

        <div class="btnBox">
          <el-button type="primary" class="bgc-bv" round @click="getData()"
            >查询</el-button
          >
          <el-button type="primary" class="bgc-bv" round @click="newlyAdded()"
            >新增报名人员</el-button
          >
          <el-button
            type="primary"
            class="bgc-bv"
            round
            @click="exportStudent()"
            >导出</el-button
          >
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :header-cell-style="tableHeader"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod" />
          <el-table-column
            label="学员"
            align="center"
            show-overflow-tooltip
            prop="userName"
          />
          <el-table-column
            label="学员手机号"
            align="center"
            show-overflow-tooltip
            prop="mobile"
          />
          <el-table-column
            label="在职单位"
            align="center"
            show-overflow-tooltip
            prop="compName"
          />
          <el-table-column
            label="残疾账号"
            align="center"
            show-overflow-tooltip
            prop="disabilityCert"
          />
          <el-table-column
            label="提交时间"
            align="center"
            show-overflow-tooltip
            prop="createTime"
          />
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <el-dialog
      title="新增学员"
      :visible.sync="newlyAddedLoading"
      width="30%"
      :before-close="handleClose"
    >
      <el-form
        :model="newlyAddedData"
        :rules="rules"
        ref="newlyAddedData"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="姓名" prop="userName">
          <el-input v-model="newlyAddedData.userName"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="newlyAddedData.mobile"></el-input>
        </el-form-item>
        <el-form-item label="在职单位" prop="compName">
          <el-input v-model="newlyAddedData.compName"></el-input>
        </el-form-item>
        <el-form-item label="残疾证号" prop="disabilityCert">
          <el-input v-model="newlyAddedData.disabilityCert"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="bgc-bv" type="primary" @click="handleClose"
          >取 消</el-button
        >
        <el-button
          class="bgc-bv"
          type="primary"
          @click="newlyAddedDetermine('newlyAddedData')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "appraisalBindCourseList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 页面检索数据
      retrievalData: {
        // 姓名
        userName: "",
        // 手机号
        mobile: "",
      },
      // 新增学员 - loading
      newlyAddedLoading: false,
      // 新增学员 - 数据
      newlyAddedData: {
        userName: "", // 姓名
        mobile: "", // 手机号
        compName: "", // 在职单位
        disabilityCert: "", // 残疾证号
      },
      // 新增学员 - 数据 - 数据校验
      rules: {
        userName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [
          { required: true, validator: this.$validatePhone, trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 获取列表数据
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        userName: this.retrievalData.userName,
        mobile: this.retrievalData.mobile,
      };
      this.doFetch(
        {
          url: "/welfare/post/user/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    // 新增学员
    newlyAdded() {
      this.newlyAddedLoading = true;
    },
    // 新增学员 - 取消
    handleClose() {
      this.newlyAddedLoading = false;
      for (let key in this.newlyAddedData) {
        this.newlyAddedData[key] = "";
      }
    },
    // 新增学员 - 确认
    newlyAddedDetermine(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(
            "/welfare/post/user/insert",
            {
              userName: this.newlyAddedData.userName,
              mobile: this.newlyAddedData.mobile,
              compName: this.newlyAddedData.compName,
              disabilityCert: this.newlyAddedData.disabilityCert,
            },
            3000,
            true,
            2
          ).then((res) => {
            if (res.status == 0) {
              this.$message.success("新增成功");
              this.getData(-1);
              this.handleClose();
            }
          });
        }
      });
    },
    // 导出
    exportStudent() {
      let parmar = {};
      if (this.retrievalData.userName) {
        parmar.userName = this.retrievalData.userName;
      }
      if (this.retrievalData.mobile) {
        parmar.mobile = this.retrievalData.mobile;
      }
      this.$post("/welfare/post/user/list/export", parmar, 3000, true, 2).then(
        (res) => {
          if (res.status == "0") {
            let list = [];
            list.push(res.data);
            for (let item of list) {
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                  "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
          } else {
            this.$message.error(re.message);
          }
        }
      );
    },
  },
};
</script>
<style lang="less" scope>
</style>
