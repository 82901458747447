<template>
  <div>
    <div
      class="operationControl"
      style="display: flex; flex-direction: column; align-items: flex-start"
    >
      <div class="searchbox">
        <div title="姓名" class="searchboxItem ci-full">
          <span class="itemLabel">姓名:</span>
          <el-input
            v-model="retrievalData.userName"
            type="text"
            size="small"
            placeholder="请输入姓名"
            clearable
          />
        </div>
        <div title="手机号" class="searchboxItem ci-full">
          <span class="itemLabel">手机号:</span>
          <el-input
            v-model="retrievalData.mobile"
            type="text"
            size="small"
            placeholder="请输入联系方式"
            clearable
          />
        </div>

        <div class="btnBox">
          <el-button type="primary" class="bgc-bv" round @click="getData()"
            >查询</el-button
          >
          <el-button type="primary" class="bgc-bv" round @click="newlyAdded()"
            >新增报名人员</el-button
          >
          <el-button
            type="primary"
            class="bgc-bv"
            round
            @click="exportStudent()"
            >导出</el-button
          >
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :header-cell-style="tableHeader"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            fixed
            :index="indexMethod"
          />
          <el-table-column
            label="学员姓名"
            align="center"
            show-overflow-tooltip
            prop="userName"
            min-width="100"
            fixed="left"
          />
          <el-table-column
            label="手机号码"
            align="center"
            show-overflow-tooltip
            prop="mobile"
            min-width="120"
            fixed="left"
          />
          <el-table-column
            label="性别"
            align="center"
            show-overflow-tooltip
            prop="sex"
            min-width="60"
            fixed="left"
          >
            <template slot-scope="{ row }">
              {{ $setDictionary("SEX", row.sex) }}
            </template>
          </el-table-column>
          <el-table-column
            label="出生年月"
            align="center"
            show-overflow-tooltip
            prop="birthday"
            min-width="120"
          />
          <el-table-column
            label="所在城市"
            align="center"
            show-overflow-tooltip
            prop="areaName"
            min-width="200"
          />
          <el-table-column
            label="期望工作城市"
            align="center"
            show-overflow-tooltip
            prop="expectAreaName"
            min-width="200"
          />
          <el-table-column
            label="学历信息"
            align="center"
            show-overflow-tooltip
            prop="userEducation"
            min-width="120"
          />
          <el-table-column
            label="是否在职"
            align="center"
            show-overflow-tooltip
            prop="onjobState"
            min-width="100"
          >
            <template slot-scope="{ row }">
              {{ filterYesno(row.onjobState) }}
            </template>
          </el-table-column>
          <el-table-column
            label="到岗时间"
            align="center"
            show-overflow-tooltip
            prop="reportTime"
            min-width="120"
          />
          <el-table-column
            label="薪资要求"
            align="center"
            show-overflow-tooltip
            prop="salaryRequire"
            min-width="120"
          />
          <el-table-column
            label="求职目标行业"
            align="center"
            show-overflow-tooltip
            prop="userIndustry"
            min-width="120"
          />
          <el-table-column
            label="是否接收外地务工"
            align="center"
            show-overflow-tooltip
            prop="otherPlaces"
            min-width="120"
          >
            <template slot-scope="{ row }">
              {{ filterYesno(row.otherPlaces) }}
            </template>
          </el-table-column>
          <el-table-column
            label="主要工作经历"
            align="center"
            show-overflow-tooltip
            prop="workExperience"
            min-width="300"
          />
          <el-table-column
            label="提交时间"
            align="center"
            show-overflow-tooltip
            prop="createTime"
            min-width="150"
            fixed="right"
          />
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <el-dialog
      title="新增学员"
      :visible.sync="newlyAddedLoading"
      v-if="newlyAddedLoading"
      width="650px"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div class="common-dialog">
        <el-form
          :model="newlyAddedData"
          :rules="rules"
          ref="newlyAddedData"
          label-width="135px"
          class="demo-ruleForm"
        >
          <el-form-item label="姓名" prop="userName">
            <el-input v-model="newlyAddedData.userName"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-select size="small" v-model="newlyAddedData.sex" clearable>
              <el-option
                v-for="item in sexList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="就业信息" prop="serviceId">
          <el-select
            size="small"
            v-model="newlyAddedData.serviceId"
            no-data-text="请添加并发布就业信息"
            clearable
          >
            <el-option
              v-for="item in serviceList"
              :key="item.serviceId"
              :label="item.serviceName"
              :value="item.serviceId"
            ></el-option>
          </el-select>
        </el-form-item> -->
          <el-form-item label="出生年月" prop="birthday">
            <el-date-picker
              v-model="newlyAddedData.birthday"
              type="date"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input v-model="newlyAddedData.mobile"></el-input>
          </el-form-item>
          <el-form-item label="所在城市" prop="areaId">
            <el-cascader
              :props="{ emitPath: false }"
              v-model="newlyAddedData.areaId"
              :options="areaList"
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="学历信息" prop="userEducation">
            <el-select
              size="small"
              v-model="newlyAddedData.userEducation"
              clearable
            >
              <el-option
                v-for="item in userEducationList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否在职" prop="onjobState">
            <el-select
              size="small"
              v-model="newlyAddedData.onjobState"
              clearable
            >
              <el-option
                v-for="item in yseNo_list"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="到岗时间" prop="reportTime">
            <el-select
              size="small"
              v-model="newlyAddedData.reportTime"
              clearable
            >
              <el-option
                v-for="item in reportTimeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="薪资要求" prop="salaryRequire">
            <el-select
              size="small"
              v-model="newlyAddedData.salaryRequire"
              clearable
            >
              <el-option
                v-for="item in salaryRequireList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="求职目标行业" prop="userIndustry">
            <el-select
              size="small"
              v-model="newlyAddedData.userIndustry"
              clearable
            >
              <el-option
                v-for="item in userIndustryList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否接受外地务工" prop="otherPlaces">
            <el-select
              size="small"
              v-model="newlyAddedData.otherPlaces"
              clearable
            >
              <el-option
                v-for="item in yseNo_list"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="期望工作城市" prop="expectAreaId">
            <el-cascader
              :props="{ emitPath: false }"
              v-model="newlyAddedData.expectAreaId"
              :options="areaList"
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="工作经历" prop="workExperience">
            <el-input
              type="textarea"
              maxlength="200"
              resize="none"
              :autosize="{ minRows: 3, maxRows: 5 }"
              v-model="newlyAddedData.workExperience"
            />
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="bgc-bv" type="primary" @click="handleClose"
          >取 消</el-button
        >
        <el-button
          class="bgc-bv"
          type="primary"
          @click="newlyAddedDetermine('newlyAddedData')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "appraisalBindCourseList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 页面检索数据
      retrievalData: {
        // 姓名
        userName: "",
        // 手机号
        mobile: "",
      },
      // 新增学员 - loading
      newlyAddedLoading: false,
      // 新增学员 - 数据
      newlyAddedData: {
        userName: "", // 姓名
        sex: "", // 性别
        // serviceId: "", // 就业信息
        birthday: "", // 出生年月
        mobile: "", // 手机号
        areaId: "", // 所在城市
        userIndustry: "", // 求职目标行业
        userEducation: "", // 学历信息
        onjobState: "", // 是否在职
        reportTime: "", // 到岗时间
        salaryRequire: "", // 薪资要求
        otherPlaces: "", // 是否接受外地务工
        expectAreaId: "", // 期望工作城市
        workExperience: "", // 工作经历
      },
      // 新增学员 - 数据 - 数据校验
      rules: {
        userName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        // serviceId: [{ required: true, message: "请选择就业信息", trigger: "change" }],
        mobile: [
          { required: true, validator: this.$validatePhone, trigger: "blur" },
        ],
        areaId: [
          { required: true, message: "请选择所在城市", trigger: "change" },
        ],
        userIndustry: [
          { required: true, message: "请选择求职目标行业", trigger: "change" },
        ],
        onjobState: [
          { required: true, message: "请选择是否在职", trigger: "change" },
        ],
        reportTime: [
          { required: true, message: "请选择到岗时间", trigger: "change" },
        ],
        salaryRequire: [
          { required: true, message: "请选择薪资要求", trigger: "change" },
        ],
        otherPlaces: [
          { required: true, message: "请选择是否接受外地务工", trigger: "change" },
        ],
        expectAreaId: [
          { required: true, message: "请选择期望工作城市", trigger: "change" },
        ],
      },
      sexList: [], //性别list
      serviceList: [], //就业信息list
      areaList: [], //所在城市list
      userIndustryList: [], //求职目标行业list
      userEducationList: [], //学历list
      reportTimeList: [], //到岗时间list
      salaryRequireList: [], //薪资要求list
      yseNo_list: [
        { value: "0", label: "否" },
        { value: "1", label: "是" },
      ], // 是否列表
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  created() {
    this.getDropDownData();
    this.getcity();
    this.getServiceList();
  },
  methods: {
    filterYesno(name) {
      let res;
      switch (name) {
        case "1":
          res = "是";
          break;
        case "0":
          res = "否";
          break;
        default:
          res = "";
          break;
      }
      return res;
    },
    // 获取列表数据
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        userName: this.retrievalData.userName,
        mobile: this.retrievalData.mobile,
      };
      this.doFetch(
        {
          url: "/job/service/user/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    // 获取 all- 下拉数据
    getDropDownData() {
      //性别list
      const sexList = this.$setDictionary("SEX", "list");
      for (const key in sexList) {
        this.sexList.push({
          value: key,
          label: sexList[key],
        });
      }
      console.log(this.sexList);
      //求职目标行业list
      const userIndustryList = this.$setDictionary(
        "MINI_JOB_SERVICE_INDUSRTRY",
        "list"
      );
      for (const key in userIndustryList) {
        this.userIndustryList.push({
          value: key,
          label: userIndustryList[key],
        });
      }
      //学历list
      const userEducationList = this.$setDictionary("EDUCATION", "list");
      for (const key in userEducationList) {
        this.userEducationList.push({
          value: key,
          label: userEducationList[key],
        });
      }
      //到岗时间list

      const reportTimeList = this.$setDictionary(
        "MINI_JOB_SERVICE_REPORT",
        "list"
      );
      for (const key in reportTimeList) {
        this.reportTimeList.push({
          value: key,
          label: reportTimeList[key],
        });
      }
      //薪资要求list
      const salaryRequireList = this.$setDictionary(
        "MINI_JOB_SERVICE_SALARY",
        "list"
      );
      for (const key in salaryRequireList) {
        this.salaryRequireList.push({
          value: key,
          label: salaryRequireList[key],
        });
      }
    },
    /**
     *  行政区划
     */
    getcity() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.areaList = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    /**
     *  就业信息
     */
    getServiceList() {
      this.$post("/job/service/publishList", {}, 3000, true, 2)
        .then((res) => {
          this.serviceList = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    // 新增学员
    newlyAdded() {
      this.newlyAddedLoading = true;
    },
    // 新增学员 - 取消
    handleClose() {
      this.newlyAddedLoading = false;
      for (let key in this.newlyAddedData) {
        this.newlyAddedData[key] = "";
      }
    },
    // 新增学员 - 确认
    newlyAddedDetermine(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.newlyAddedData);
          this.$post(
            "/job/service/user/insert",
            this.newlyAddedData,
            3000,
            true,
            2
          ).then((res) => {
            if (res.status == 0) {
              this.$message.success("新增成功");
              this.getData(-1);
              this.handleClose();
            }
          });
        }
      });
    },
    // 导出
    exportStudent() {
      let parmar = {};
      if (this.retrievalData.userName) {
        parmar.userName = this.retrievalData.userName;
      }
      if (this.retrievalData.mobile) {
        parmar.mobile = this.retrievalData.mobile;
      }
      this.$post("/job/service/user/list/export", parmar, 3000, true, 2).then(
        (res) => {
          if (res.status == "0") {
            let list = [];
            list.push(res.data);
            for (let item of list) {
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                  "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
          } else {
            this.$message.error(re.message);
          }
        }
      );
    },
  },
};
</script>
<style lang="less" scope>
.el-tooltip__popper {
  max-width: 40% !important;
}
.el-dialog__body{
  padding: 0 !important;
}
.common-dialog {
  padding: 20px 60px 20px 20px;
  margin:10px;
  max-height: 50vh !important;
  overflow-y: scroll !important;
}
</style>
